import api from '@admin/api/service';

export default {
  postNewBooking(payload) {
    return api.post(`/singgahsini/api/booking`, payload);
  },
  getListingAvailableRooms(listingId, params) {
    return api.get(
      `/singgahsini/api/booking/room-allotment-available/${listingId}`,
      { params }
    );
  },
  getBookingContractById(contractId, cancelToken) {
    return api.get(`/singgahsini/api/booking/contract/${contractId}`, {
      cancelToken,
    });
  },
  postRoomRelocation(payload) {
    return api.post('/singgahsini/api/booking/relocation-room-unit', payload);
  },
};
